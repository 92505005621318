import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Image, Layout } from "../../components";

import styles from "./index.module.css";

class Home extends Component {
  render() {
    const cinemas = this.props.cinemas;

    return (
      <Layout>
        <div className={styles.gridContainer}>
          {cinemas.map(cinema => {
            const firstImage = cinema.images ? cinema.images[0] : {};

            return (
              <div className={styles.gridItem} key={cinema.url}>
                <Link className={styles.link} to={`/cinema/${cinema.url}`}>
                  <div>
                    {
                      <Image
                        imageWrapper={firstImage}
                        queryParams={"w=300&h=300&fit=thumb"}
                        style={styles.thumbnail}
                      />
                    }
                    <div className={styles.title}>{cinema.title}</div>
                    <div className={styles.dateField}>
                      {cinema.city &&
                        cinema.country &&
                        [cinema.city, cinema.country].join(", ")}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export default Home;
