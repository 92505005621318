import React, { Component } from "react";
import HamburgerMenu from "react-hamburger-menu";
import { NavLink } from "react-router-dom";

import { NavBar } from "../";

import styles from "./index.module.css";

import logo from "../../assets/images/logo_black_02.jpg";
// import betaBanner from "../../assets/images/beta_banner.png";

class SiteHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtonClicked: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ showButtonClicked: !this.state.showButtonClicked });
  }

  render() {
    return (
      <>
        <div className={styles.logoHeader}>
          <NavLink to={`/`} className={styles.imageLink}>
            <img className={styles.image} src={logo} alt="Logo" />
          </NavLink>
          <div className={styles.hamburgerContainer}>
            <HamburgerMenu
              isOpen={this.state.showButtonClicked}
              menuClicked={this.handleClick}
              width={40}
              height={20}
              strokeWidth={1}
              rotate={0}
              color="black"
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        </div>
        <NavBar visible={this.state.showButtonClicked} />
      </>
    );
  }
}

export default SiteHeader;
