import React, { Component } from "react";

import {
  Image,
  ImageCounter,
  ImageGrid,
  ImageNavigationLinks,
  Layout,
  StyledLink,
  TextInfoField
} from "../../components";

import styles from "./index.module.css";

// NOTE - I think this doesn't actually get rendered anymore... check

class CinemaImage extends Component {
  render() {
    const imageWrapper = this.props.image;
    const cinema = this.props.cinema;
    const images = cinema.images;
    const index = images.findIndex(img => img.id === imageWrapper.id);

    const previousIndex = (index - 1 + images.length) % images.length;
    const nextIndex = (index + 1) % images.length;

    const previousImage = images[previousIndex];
    const nextImage = images[nextIndex];

    return (
      <Layout>
        {images.length > 1 && (
          <ImageCounter
            cinema={cinema}
            label={`${index + 1} of ${images.length}`}
            previousImage={previousImage}
            nextImage={nextImage}
          />
        )}

        <div className={styles.mainImageContainer}>
          <Image
            imageWrapper={imageWrapper}
            queryParams={"h=750"}
            style={styles.image}
          />

          {images.length > 1 && (
            <ImageNavigationLinks
              cinema={cinema}
              previousImage={previousImage}
              nextImage={nextImage}
            />
          )}
        </div>

        <h2>{imageWrapper.title}</h2>

        <div className={styles.infoContainer}>
          {imageWrapper.photographer && (
            <TextInfoField
              label="Photographer"
              value={imageWrapper.photographer}
            />
          )}

          {imageWrapper.dateYear && (
            <TextInfoField label="Year" value={imageWrapper.dateYear} />
          )}
        </div>

        {images.length > 1 && (
          <div className={styles.imageGallery}>
            <ImageGrid images={images} cinema={cinema} />
          </div>
        )}

        <StyledLink
          href={`/cinema/${cinema.url}`}
          label={`Back to ${cinema.title}`}
        />
      </Layout>
    );
  }
}

export default CinemaImage;
