import React, { Component } from "react"
import ReactMarkdown from "react-markdown"
import { Link } from "react-router-dom"
import Lightbox from "react-images"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import {
  AuthorInfoField,
  DataBox,
  Image,
  ImageGrid,
  LastUpdateField,
  Layout,
  StyledLink
} from "../../components"

import { dateString } from "../../utils/helper"

import styles from "./index.module.css"

const ImageCaption = image => {
  const imageLicence = (
    <StyledLink
      href={image.image.license.url}
      label={image.image.license.name}
      external={true}
    />
  )

  return (
    <>
      {`${image.image.title || "No title"} (${image.image.photographer ||
        "No photographer"} | ${image.image.dateYear || "No date"} | `}

      {image.image.license && "License: "}
      {image.image.license && imageLicence}
      {!image.image.license && "No license specified"}
      {!!image.image.courtesyOf && ` | Courtesy of: ${image.image.courtesyOf}`}

      {")"}
    </>
  )
}

class Cinema extends Component {
  constructor(props) {
    super(props)

    this.cinema = this.props.cinema
    this.author = this.cinema.author

    this.images = this.cinema.images || [{}]
    this.heroImageWrapper = this.images ? this.images[0] : {}
    this.lightBoxImages = this.images.map(image => ({
      src: image.max_url,
      srcSet: image.srcSet,
      caption: <ImageCaption image={image} />,
      alt: image.description
    }))

    this.state = {
      lightboxOpen: false,
      currentImage: 0
    }

    this.openLightbox = this.openLightbox.bind(this)
    this.openLightboxWithIndex = this.openLightboxWithIndex.bind(this)
    this.gotoPrevLightboxImage = this.gotoPrevLightboxImage.bind(this)
    this.gotoNextLightboxImage = this.gotoNextLightboxImage.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this)
    this.handleImageClick = this.handleImageClick.bind(this)
  }

  openLightbox(event) {
    event.preventDefault()
    this.setState({ lightboxOpen: true, currentImage: 0 })
    // Example of how to update URL state
    // window.history.pushState("page2", "Title", "/page2.php");
  }

  openLightboxWithIndex(index, event) {
    event.preventDefault()
    this.setState({ lightboxOpen: true, currentImage: index + 1 })
  }

  closeLightbox(event) {
    event && event.preventDefault()
    this.setState({ lightboxOpen: false })
  }

  gotoPrevLightboxImage() {
    const { currentImage } = this.state
    this.setState({ currentImage: currentImage - 1 })
  }

  gotoNextLightboxImage() {
    const { currentImage } = this.state
    this.setState({ currentImage: currentImage + 1 })
  }

  handleThumbnailClick(index) {
    this.setState({ currentImage: index })
  }

  handleImageClick() {
    const { currentImage } = this.state

    if (currentImage === this.images.length - 1) {
      return
    }

    this.setState({ currentImage: currentImage + 1 })
  }

  render() {
    return (
      <Layout>
        <Lightbox
          images={this.lightBoxImages}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxOpen}
          onClickPrev={this.gotoPrevLightboxImage}
          onClickNext={this.gotoNextLightboxImage}
          onClickThumbnail={this.handleThumbnailClick}
          onClickImage={this.handleImageClick}
          onClose={this.closeLightbox}
          showThumbnails={false}
          preloadNextImage={true}
          backdropClosesModal={true}
          width={1280}
          theme={{ container: { background: "rgba(0, 0, 0, 1)" } }}
        />

        <div className={styles.topSection}>
          <div className={styles.title}>
            <h1 className={styles.cinemaTitle}>{this.cinema.title}</h1>
            {this.cinema.city && this.cinema.country && (
              <h2 className={styles.cityCountryHeader}>
                {[this.cinema.city, this.cinema.country].join(", ")}
              </h2>
            )}

            {this.cinema.alternativeNames && (
              <h2>
                {`Alternative names: ${this.cinema.alternativeNames.join(
                  ", "
                )}`}
              </h2>
            )}

            {this.cinema.status && (
              <h2>{this.cinema.status && `Status: ${this.cinema.status}`}</h2>
            )}
          </div>

          <div className={styles.info}>
            {this.author && <AuthorInfoField name={this.author.name} />}
            {this.cinema.updatedAt && (
              <LastUpdateField name={dateString(this.cinema.updatedAt)} />
            )}
          </div>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.leftContainer}>
            <div className={styles.imagesContainer}>
              {this.images.length > 0 && (
                <div className={styles.heroImage}>
                  <Link
                    className={styles.link}
                    to={`/cinema/${this.cinema.url}/images/${this.heroImageWrapper.id}`}
                    onClick={this.openLightbox}
                  >
                    <Image
                      imageWrapper={this.heroImageWrapper}
                      queryParams={"w=800"}
                      style={styles.singleImage}
                    />
                  </Link>

                  {this.heroImageWrapper.title && (
                    <div className={styles.imageTitle}>
                      {this.heroImageWrapper.title}
                    </div>
                  )}
                </div>
              )}
              {this.images.length > 1 && (
                <ImageGrid
                  images={this.images.slice(1)}
                  cinema={this.cinema}
                  onClickHandler={this.openLightboxWithIndex}
                />
              )}
            </div>
          </div>

          <div className={styles.contentContainer}>
            <DataBox cinema={this.cinema} />

            <div className={styles.description}>
              <ReactMarkdown source={this.cinema.description} />
            </div>

            {this.cinema.linksAndReferences && (
              <div className={styles.description}>
                <h3>Links and References</h3>
                <ReactMarkdown source={this.cinema.linksAndReferences} />
              </div>
            )}

            {this.cinema.incomplete && (
              <div className={styles.incomplete}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                The description of this cinema is incomplete and may lack
                important information. In case you can contribute to this entry,
                don't hesitate to contact us by email (
                <a href="mailto:editorial@cinelandmarks.org">
                  editorial@cinelandmarks.org
                </a>
                ).
              </div>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Cinema
