import React from "react";

import { Layout } from "../../components";

const NoMatch = () => (
  <Layout>
    <h2>404 route not found</h2>
  </Layout>
);

export default NoMatch;
