import React from "react";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

const Footer = () => (
  <div className={styles.footer}>
    <Link className={styles.link} to={`/terms/`}>
      TERMS & CONDITIONS
    </Link>{" "}
    <Link className={styles.link} to={`/impressum/`}>
      IMPRESSUM
    </Link>{" "}
    <Link className={styles.link} to={`/faq/`}>
      F.A.Q.
    </Link>
  </div>
);

export default Footer;
