// Removes `fields` and `sys` sub keys from contentful payload so that
// it matches the GraphQL returned payload in the static app

export const sanitiseData = obj => {
  const cleanObj = sanitiseNodeData(obj)

  // Convert images to more convenient structure
  const images =
    cleanObj.images &&
    cleanObj.images.map(image => {
      const cleanImg = image
      if (image.image && image.image.file) {
        cleanImg.max_url = image.image.file.url + "?w=2000"
        cleanImg.srcSet = [
          `${image.image.file.url}?w=1280 1280w`,
          `${image.image.file.url}?w=1024 1024w`,
          `${image.image.file.url}?w=800 800w`,
          `${image.image.file.url}?w=500 500w`,
          `${image.image.file.url}?w=320 320w`
        ]
      }
      return cleanImg
    })
  cleanObj.images = images

  return cleanObj
}

const sanitiseNodeData = obj => {
  let santisedObj = {}

  if (Object.keys(obj).includes("fields")) {
    Object.keys(obj.fields).forEach(field => {
      if (
        obj.fields[field] &&
        Object.keys(obj.fields[field]).includes("fields")
      ) {
        // if it's an object and contains "fields", run recursively
        santisedObj[field] = sanitiseNodeData(obj.fields[field])
      } else if (Array.isArray(obj.fields[field])) {
        // if an array and children contain "fields", run recursively
        santisedObj[field] = obj.fields[field].map(subObj =>
          sanitiseNodeData(subObj)
        )
      } else {
        // if just a normal field, just remove fields
        santisedObj[field] = obj.fields[field]
      }
    })
  } else {
    santisedObj = obj
  }

  if (Object.keys(obj).includes("sys")) {
    Object.keys(obj.sys).forEach(field => (santisedObj[field] = obj.sys[field]))
  }

  console.log({ santisedObj })

  return santisedObj
}

export const sanitiseCineAdminData = obj => {
  let santisedObj = {}

  Object.keys(obj).forEach(field => {
    if (field === "address_city") {
      santisedObj.city = obj[field]
    } else if (field === "address_country") {
      santisedObj.country = obj[field]
    } else if (field === "address_state") {
      santisedObj.state = obj[field]
    } else if (field === "address_street") {
      santisedObj.street = obj[field]
    } else if (field === "address_zipcode") {
      santisedObj.zipCode = obj[field]
    } else if (field === "coord_lat") {
      santisedObj.address = {} // Relies on lat coming before long
      santisedObj.address["lat"] = obj[field]
    } else if (field === "coord_long") {
      santisedObj.address["lon"] = obj[field]
    } else if (field === "year_of_construction") {
      santisedObj.yearOfConstruction = obj[field]
    } else if (field === "year_of_first_opening") {
      santisedObj.yearOfFirstOpening = obj[field]
    } else if (field === "year_of_reopening") {
      santisedObj.yearOfReopening = obj[field]
    } else if (field === "number_of_screens") {
      santisedObj.numOfScreens = obj[field]
    } else if (field === "number_of_seats") {
      santisedObj.numOfSeats = obj[field]
    } else if (field === "references") {
      santisedObj.linksAndReferences = obj[field]
    } else if (field === "user") {
      santisedObj.author = {}
      santisedObj.author.name = `${obj[field].first_name} ${obj[field].last_name}`
    } else if (field === "updated_at") {
      santisedObj.updatedAt = obj[field]
    } else if (field === "alternative_names") {
      santisedObj.alternativeNames = obj[field] && obj[field].split(",")
    } else if (field === "architectural_styles") {
      santisedObj.architecturalStyle = obj[field]
    } else if (field === "features") {
      santisedObj.featureList = obj[field]
    } else if (field === "metadata_images") {
      const images = obj[field].map(image => {
        const cleanImg = image
        cleanImg.max_url = image.srcset.src
        cleanImg.thumb = image.srcset[600]
        cleanImg.srcSet = [
          `${image.srcset[1200]} 1200w`,
          `${image.srcset[600]} 600w`,
          `${image.srcset[300]} 300w`,
          `${image.srcset[150]} 150w`
        ]
        delete image["srcset"]

        cleanImg.license = image.image_license
        delete image["image_license"]

        cleanImg.dateYear = image.date
        delete image["date"]

        return cleanImg
      })

      santisedObj.images = images
    } else {
      santisedObj[field] = obj[field]
    }
  })

  console.log({ santisedObj })

  return santisedObj
}
