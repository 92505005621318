import React from "react";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

export default ({ elements }) => (
  <div className={styles.pillContainer}>
    {elements.map(element => (
      <Link
        key={element}
        className={styles.pill}
        to={`/cinesearch?feature=${element}`}
      >
        {element}
      </Link>
    ))}
  </div>
);
