import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faChevronCircleRight,
  faChevronCircleLeft
} from "@fortawesome/free-solid-svg-icons";

import styles from "./index.module.css";

export default ({ cinema, previousImage, nextImage }) => (
  <>
    <Link
      className={styles.navLink}
      to={`/cinema/${cinema.url}/images/${previousImage.id}`}
    >
      <div className={styles.overlayPrevious}>
        <FontAwesomeIcon icon={faCircle} size="3x" inverse />
      </div>
      <div className={styles.overlayPrevious}>
        <FontAwesomeIcon icon={faChevronCircleLeft} size="3x" />
      </div>
    </Link>

    <Link
      className={styles.navLink}
      to={`/cinema/${cinema.url}/images/${nextImage.id}`}
    >
      <div className={styles.overlayNext}>
        <FontAwesomeIcon icon={faCircle} size="3x" inverse />
      </div>
      <div className={styles.overlayNext}>
        <FontAwesomeIcon icon={faChevronCircleRight} size="3x" />
      </div>
    </Link>
  </>
);
