import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import queryString from "query-string";
import axios from "axios"

import {
  About,
  Cinema,
  CinemaImage,
  Cinemap,
  Cinestories,
  Contribute,
  Home,
  NoMatch,
  Search,
  Terms,
  Impressum,
  FAQ
} from "./pages";
import { Footer, SiteHeader } from "./components";
import { sanitiseData, sanitiseCineAdminData } from "./utils/sanitiseData";

import styles from "./App.module.css";

const contentful = require("contentful"); // Import css modules stylesheet as styles

class App extends Component {
  constructor(props) {
    console.log("Initialising App");
    super(props);

    this.state = {
      cinemas: []
    };

    const queryParams = queryString.parse(window.location.search);
    console.log(queryParams)

    if(queryParams.dataSource === "cineadmin"){
      console.log("Fetching data from Cineadmin")

      const apiUrl = process.env.REACT_APP_DATA_URL

      const _this = this;
      axios.get(`${apiUrl}/cinemas.json?preview=true`)
      .then(function (response) {
        // handle success
        _this.setState({
          // TODO - fix the sanitisation
          cinemas: response.data.map(item => sanitiseCineAdminData(item))
        });

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })


      // TODO
    } else {
      console.log("Fetching data from Contentful")

      const client = contentful.createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        host: "preview.contentful.com" // only needed for Preview API
      });

      const _this = this;
      client
        .getEntries({
          content_type: "cinema",
          include: 2
          // select: "sys.id,sys.updatedAt,fields.url,fields.title,fields.images"
        })
        .then(function(entries) {
          console.log("LOADED CINEMAS");
          _this.setState({
            cinemas: entries.items.map(item => sanitiseData(item))
          });
        });
    }
  }

  render() {
    const cinemaRoutes = this.state.cinemas.map(cinema => {
      return (
        <Route
          path={`/cinema/${cinema.url}`}
          key={`/cinema/${cinema.url}`}
          render={() => <Cinema cinema={cinema} />}
          exact={true} // otherwise image routes might not get reached
        />
      );
    });

    let cinemaImageRoutes = [];
    this.state.cinemas.forEach(cinema => {
      const images = cinema.images || [];
      const imageRoutes = images.map(image => {
        return (
          <Route
            path={`/cinema/${cinema.url}/images/${image.id}`}
            key={`/cinema/${cinema.url}/images/${image.id}`}
            render={() => <CinemaImage cinema={cinema} image={image} />}
          />
        );
      });
      cinemaImageRoutes = cinemaImageRoutes.concat(imageRoutes);
    });

    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <header>
            <SiteHeader />
          </header>

          <main>
            {this.state.cinemas && this.state.cinemas.length < 1 && (
              <div className={styles.spinner}>
                <MoonLoader />
              </div>
            )}
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Home cinemas={this.state.cinemas} />}
              />
              <Route path="/cinesearch" component={Search} />
              <Route path="/cinemap" component={Cinemap} />
              <Route path="/cinestories" component={Cinestories} />
              <Route path="/contribute" component={Contribute} />
              <Route path="/about" component={About} />
              <Route path="/terms" component={Terms} />
              <Route path="/impressum" component={Impressum} />
              <Route path="/faq" component={FAQ} />
              {cinemaRoutes}
              {cinemaImageRoutes}
              <Route component={NoMatch} />
            </Switch>
          </main>

          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
