import React from "react";

import styles from "./index.module.css";

export default ({ label }) => {
  return (
    <div className={styles.navMenu}>
      <span>{label}</span>
    </div>
  );
};
