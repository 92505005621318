import React from "react";
import { Link } from "react-router-dom";

import { Image } from "../";

import styles from "./index.module.css";

export default ({ images, cinema, onClickHandler }) => {
  return(
    <div className={styles.gridContainer}>
      {images.map((imageWrapper, index) => {
        return (
          <div className={styles.gridItem} key={imageWrapper.id}>
            <Link
              className={styles.link}
              to={`/cinema/${cinema.url}/images/${imageWrapper.id}`}
              onClick={e => onClickHandler(index, e)}
              >
              <Image
                imageWrapper={imageWrapper}
                queryParams={"w=100&h=100&fit=thumb"}
                style={styles.thumbnail}
                />
            </Link>
          </div>
        );
      })}
    </div>
  );
}
