import React from "react";

import { NavLink } from "react-router-dom";

import styles from "./index.module.css";

const NavBar = ({ visible }) => {
  return (
    <>
      <nav>
        <ul className={`${styles.navbar} ${visible && styles.showNavBar}`}>
          <li className={styles.navListItem}>
            <NavLink
              className={styles.link}
              to={`/`}
              activeClassName={styles.activeLink}
            >
              home
            </NavLink>
          </li>
          <li className={styles.navListItem}>
            <NavLink
              className={styles.link}
              to={`/cinesearch`}
              activeClassName={styles.activeLink}
            >
              cinesearch
            </NavLink>
          </li>
          <li className={styles.navListItem}>
            <NavLink
              className={styles.link}
              to={`/cinemap/`}
              activeClassName={styles.activeLink}
            >
              cinemap
            </NavLink>
          </li>
          <li className={styles.navListItem}>
            <NavLink
              className={styles.link}
              to={`/cinestories/`}
              activeClassName={styles.activeLink}
            >
              cinestories
            </NavLink>
          </li>
          <li className={styles.navListItem}>
            <NavLink
              className={styles.link}
              to={`/contribute/`}
              activeClassName={styles.activeLink}
            >
              contribute
            </NavLink>
          </li>
          <li className={styles.navListItem}>
            <NavLink
              className={styles.link}
              to={`/about/`}
              activeClassName={styles.activeLink}
            >
              about
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
