import React from "react";

import noImageFoundJpg from "../../assets/images/no-image-found.jpg";

const noImageFound = className => (
  <img
    id="heroImage"
    alt="not found"
    src={noImageFoundJpg}
    className={className}
  />
);

export default ({ imageWrapper, queryParams, style }) => {
  if(!imageWrapper){
    return null
  }

  return (
    <>
      {imageWrapper.max_url &&
        imageWrapper.srcSet && (
          <img
            alt={imageWrapper.description || imageWrapper.title}
            src={imageWrapper.max_url}
            srcSet={imageWrapper.srcSet}
            className={style}
          />
        )}
      {(!imageWrapper.max_url || !imageWrapper.max_url) && noImageFound(style)}

      {imageWrapper.revision === 0 && (
        <div>Image-With-Metadata not yet published</div>
      )}
      {imageWrapper.image &&
        imageWrapper.image.revision === 0 && (
          <div>
            <div>Image-Asset not yet published</div>
          </div>
        )}
      {!imageWrapper.max_url && <div>No image found</div>}
      {imageWrapper.max_url &&
        !imageWrapper.srcSet && <div>No image found</div>}
    </>
  );
};
